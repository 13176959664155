import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { Store } from 'Store';
import { getTotalOrderPrice, round2, timeout } from 'utils/utils';
import CodeInsert, {
  removeCouponHandler,
  removeVoucherHandler,
} from './CodeInsert';
import LoadingBox from 'components/LoadingBox';
import { deliveryCosts } from 'utils/types';

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true };
    case 'END_LOADING':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function CartSummary({
  hideCodeInsert,
  withDeliveryCosts,
  onLoadingChange,
  order,
}) {
  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    orderInfo,
    cart: { cartItems },
  } = state;

  const [orderPrices, setOrderPrices] = useState(
    getTotalOrderPrice(
      order ? order.orderItems : cartItems,
      withDeliveryCosts
        ? order
          ? deliveryCosts[order?.deliveryMethod]
          : deliveryCosts[orderInfo?.deliveryMethod ?? '']
        : 0,
      order ? order.coupon : orderInfo?.coupon ?? null,
      order ? order.vouchers : orderInfo?.vouchers ?? null
    )
  );

  useEffect(() => {
    setOrderPrices(
      getTotalOrderPrice(
        order ? order.orderItems : cartItems,
        withDeliveryCosts
          ? order
            ? deliveryCosts[order?.deliveryMethod]
            : deliveryCosts[orderInfo?.deliveryMethod ?? '']
          : 0,
        order ? order.coupon : orderInfo?.coupon ?? null,
        order ? order.vouchers : orderInfo?.vouchers ?? null
      )
    );
  }, [cartItems, orderInfo, withDeliveryCosts, order]);

  const removeVoucher = async (code) => {
    dispatch({ type: 'LOADING' });

    await timeout(1500);

    removeVoucherHandler({
      ctxDispatch,
      code: code,
    });

    dispatch({ type: 'END_LOADING' });
  };

  return (
    <>
      <ListGroup.Item key="cart_summary" className="cart_footer">
        <Row className="mt-3 text-end h6">
          <Col xs={9}>
            <div>Summe Artikel:</div>
          </Col>
          <Col
            xs={3}
            style={{
              textDecoration: 'none',
              color: 'black',
            }}
          >
            {orderPrices.productsTotalWithoutDiscount.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
            })}
          </Col>
        </Row>
        {orderPrices.hasSale && (
          <>
            <Row className="my-3 text-end h6">
              <Col xs={9}>
                <div>Sale:</div>
              </Col>
              <Col
                xs={3}
                style={{
                  color: 'red',
                }}
              >
                {round2(
                  orderPrices.productsTotalWithDiscount -
                    orderPrices.productsTotalWithoutDiscount
                ).toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Col>
            </Row>
            <Row
              className="pt-3 mb-3 text-end h6"
              style={{
                borderTop: '2px solid var(--bs-list-group-border-color)',
              }}
            >
              <Col xs={9}>
                <div>Zwischensumme:</div>
              </Col>
              <Col xs={3}>
                {orderPrices.productsTotalWithDiscount.toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Col>
            </Row>
          </>
        )}
        {((order && order.coupon) ||
          (orderInfo?.coupon && orderPrices.couponDiscount < 0)) && (
          <>
            <Row className="mt-3 text-end h6 mb-0">
              <Col xs={9}>
                <div>Rabattcode:</div>
              </Col>
              <Col
                xs={3}
                style={{
                  color: 'red',
                }}
              >
                {orderPrices.couponDiscount.toLocaleString('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Col>
            </Row>
            <Row
              className="text-end"
              style={{
                fontSize: '0.85rem',
              }}
            >
              <Col xs={9}>
                ({(order?.coupon || orderInfo?.coupon).description})
              </Col>
              {!order && (
                <Col
                  xs={3}
                  className="text-end"
                  style={{
                    color: 'gray',
                    cursor: 'pointer',
                  }}
                  onClick={() => removeCouponHandler({ ctxDispatch })}
                >
                  Entfernen
                </Col>
              )}
            </Row>
          </>
        )}
        {withDeliveryCosts && (
          <Row className="mt-3 text-end h6">
            <Col xs={9}>
              <div>Lieferkosten:</div>
            </Col>
            <Col>
              {deliveryCosts[
                order?.deliveryMethod || orderInfo?.deliveryMethod
              ].toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
              })}
            </Col>
          </Row>
        )}
      </ListGroup.Item>
      {hideCodeInsert ||
      (orderInfo?.coupon &&
        orderPrices?.couponDiscount &&
        orderPrices?.valueToPay === 0) ? (
        <></>
      ) : (
        <ListGroup.Item className="cart_footer">
          <CodeInsert
            withEmail={withDeliveryCosts}
            onLoadingChange={onLoadingChange}
          />
        </ListGroup.Item>
      )}
      <ListGroup.Item key="cart_total" className="cart_footer">
        <Row
          className={`mt-3 text-end h${
            order || orderPrices?.remainingVoucherValue > 0 ? '6' : '5'
          }`}
        >
          <Col xs={9}>
            <div>Gesamt:</div>
          </Col>
          <Col xs={3}>
            {orderPrices.totalPrice.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
            })}
          </Col>
        </Row>
        {((order && order.vouchers) ||
          orderPrices?.remainingVoucherValue > 0) && (
          <>
            {loading ? (
              <LoadingBox />
            ) : (
              <>
                {(order?.vouchers || orderPrices?.voucherData).map(
                  (voucher) => {
                    return (
                      <React.Fragment key={`voucher-${voucher.number}`}>
                        <Row className="mt-3 text-end h6 mb-0">
                          <Col xs={9}>
                            <div>{`Wertgutschein #${voucher.number}:`}</div>
                          </Col>
                          <Col
                            xs={3}
                            style={{
                              color: 'red',
                            }}
                          >
                            {voucher.usedValue.toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            })}
                          </Col>
                        </Row>
                        <Row
                          className="text-end"
                          style={{
                            fontSize: '0.85rem',
                          }}
                        >
                          <Col xs={9}>
                            Restwert:{' '}
                            {voucher.remainingValue.toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            })}
                          </Col>
                          {!order && (
                            <Col
                              xs={3}
                              className="text-end"
                              style={{
                                color: 'gray',
                                cursor: 'pointer',
                              }}
                              onClick={() => removeVoucher(voucher.code)}
                            >
                              Entfernen
                            </Col>
                          )}
                        </Row>
                      </React.Fragment>
                    );
                  }
                )}
                {order && order.valueToPay > 0 && (
                  <>
                    <Row
                      className="mt-3 text-end h6 mb-0"
                      // style={{
                      //   borderTop: '2px solid var(--bs-list-group-border-color)',
                      // }}
                    >
                      <Col xs={9}>
                        <div>Bereits gezahlt:</div>
                      </Col>
                      <Col
                        xs={3}
                        style={{
                          color: 'red',
                        }}
                      >
                        {(!order || !order?.isPaid
                          ? 0
                          : -order.valueToPay
                        ).toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </Col>
                    </Row>
                    <Row
                      className="text-end mb-3"
                      style={{
                        fontSize: '0.85rem',
                      }}
                    >
                      <Col xs={9}>Bezahlart: {order.paymentMethod}</Col>
                    </Row>
                  </>
                )}
                <Row
                  className="pt-3 text-end h5"
                  style={{
                    borderTop: '2px solid var(--bs-list-group-border-color)',
                  }}
                >
                  <Col xs={9}>
                    <div>Zu zahlen:</div>
                  </Col>
                  <Col xs={3}>
                    {(!order || !order?.isPaid
                      ? orderPrices.valueToPay
                      : 0
                    ).toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </ListGroup.Item>
    </>
  );
}
