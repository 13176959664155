import { createContext, useReducer } from 'react';
import { getTotalProductsPrice } from 'utils/utils';
import { v4 as uuidv4 } from 'uuid';

export const Store = createContext();

const initialState = {
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,

  orderInfo: localStorage.getItem('orderInfo')
    ? JSON.parse(localStorage.getItem('orderInfo'))
    : null,

  cart: {
    paymentMethod: localStorage.getItem('paymentMethod')
      ? localStorage.getItem('paymentMethod')
      : '',
    /*
    shippingAddress: localStorage.getItem('shippingAddress')
      ? JSON.parse(localStorage.getItem('shippingAddress'))
      : {},
      */
    totalPrice: localStorage.getItem('totalPrice')
      ? JSON.parse(localStorage.getItem('totalPrice'))
      : '',

    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
    /*
    reservedItems: localStorage.getItem('cartReserved')
      ? JSON.parse(localStorage.getItem('cartReserved'))
      : [],
      */
  },
  reserved: {
    IDs: localStorage.getItem('reservedProducts')
      ? JSON.parse(localStorage.getItem('reservedProducts'))
      : [],
    until: localStorage.getItem('reservedUntil')
      ? JSON.parse(localStorage.getItem('reservedUntil'))
      : '',
  },
  id: localStorage.getItem('braunbaerchen.id')
    ? localStorage.getItem('braunbaerchen.id')
    : '',
  lastHeight: 0,
  googleMaps: localStorage.getItem('braunbaerchen.consent-google-maps')
    ? localStorage.getItem('braunbaerchen.consent-google-maps')
    : false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_UUID':
      const id = uuidv4();
      localStorage.setItem('braunbaerchen.id', id);
      return { ...state, id: id };
    case 'CART_ADD_ITEM':
      //add to cart
      const newItem = action.payload;
      const existItem = state.cart.cartItems.find(
        (item) => item._id === newItem._id
      );
      const cartItems = existItem
        ? state.cart.cartItems.map((item) =>
            item._id === existItem._id ? newItem : item
          )
        : [...state.cart.cartItems, newItem];
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return { ...state, cart: { ...state.cart, cartItems } };
    case 'CART_REMOVE_ITEM': {
      const cartItems = state.cart.cartItems.filter(
        (item) => item._id !== action.payload._id
      );
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      return { ...state, cart: { ...state.cart, cartItems } };
    }
    case 'CART_UPDATE_ITEMS': {
      const removedIds =
        action.payload.data.soldProducts.length > 0
          ? action.payload.data.soldProducts.map((p) => p._id)
          : [];

      const reservedIds = action.payload.data.products
        .filter((rp) => new Date(rp.reservedUntil) > new Date())
        .map((r) => r._id);

      const notFoundIds =
        action.payload.data.notFound.length > 0
          ? action.payload.data.notFound
          : [];

      let cartItems = state.cart.cartItems
        // .concat(state.cart.reservedItems)
        .filter(
          (item) =>
            !removedIds.includes(item._id) &&
            !reservedIds.includes(item._id) &&
            !notFoundIds.includes(item._id)
        );

      cartItems.forEach((p) => {
        p.price = action.payload.data.products.find(
          (n) => p._id === n._id
        ).price;
        p.sale = action.payload.data.products.find((n) => p._id === n._id).sale;
        p.discount = action.payload.data.products.find(
          (n) => p._id === n._id
        ).discount;
      });

      const totalPrice =
        getTotalProductsPrice(cartItems).productsTotalWithDiscount; //cartItems.reduce((a, c) => a + c.price, 0);

      /*let reservedItems = state.cart.cartItems
        .concat(state.cart.reservedItems)
        .filter((item) => reservedIds.includes(item._id));
      reservedItems.forEach((p) => {
        p.reservedUntil = action.payload.data.products.find(
          (n) => p._id === n._id
        ).reservedUntil;
      }); */

      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      localStorage.setItem('totalPrice', JSON.stringify(totalPrice));
      return { ...state, cart: { ...state.cart, cartItems, totalPrice } };
    }
    case 'UPDATE_TOTALPRICE': {
      localStorage.setItem('totalPrice', JSON.stringify(action.payload));
      return { ...state, cart: { ...state.cart, totalPrice: action.payload } };
    }
    case 'CART_CLEAR':
      return { ...state, cart: { ...state.cart, cartItems: [] } };
    case 'ORDER_SUCCESSFUL':
      localStorage.removeItem('cartItems');
      localStorage.removeItem('totalPrice');
      localStorage.removeItem('reservedProducts');
      localStorage.removeItem('reservedUntil');
      let orderInfoSuccessfullOrder = JSON.parse(
        localStorage.getItem('orderInfo')
      );
      if (orderInfoSuccessfullOrder?.coupon) {
        delete orderInfoSuccessfullOrder.coupon;
      }
      if (orderInfoSuccessfullOrder?.vouchers) {
        delete orderInfoSuccessfullOrder.vouchers;
      }
      localStorage.setItem(
        'orderInfo',
        JSON.stringify(orderInfoSuccessfullOrder)
      );
      return {
        ...state,
        cart: { ...state.cart, cartItems: [], totalPrice: 0 },
        orderInfo: { ...state.orderInfo, coupon: {}, vouchers: null },
        reserved: {},
      };
    case 'USER_SIGNIN':
      return { ...state, userInfo: action.payload };
    case 'USER_SIGNOUT':
      return {
        ...state,
        userInfo: null,
        cart: {
          cartItems: [],
          //shippingAddress: {},
          paymentMethod: '',
        },
      };
    case 'SAVE_USERDATA':
      const newOrderInfo = {
        ...state.orderInfo,
        userData: action.payload,
      };
      localStorage.setItem('orderInfo', JSON.stringify(newOrderInfo));
      return {
        ...state,
        orderInfo: newOrderInfo,
      };
    case 'SAVE_DELIVERY_METHOD':
      const newOrderInfoDelivery = {
        ...state.orderInfo,
        ...action.payload,
      };
      localStorage.setItem('orderInfo', JSON.stringify(newOrderInfoDelivery));
      return {
        ...state,
        orderInfo: newOrderInfoDelivery,
      };
    case 'SAVE_PAYMENT_METHOD':
      const newOrderInfoPayment = {
        ...state.orderInfo,
        ...action.payload,
      };
      localStorage.setItem('orderInfo', JSON.stringify(newOrderInfoPayment));
      return {
        ...state,
        orderInfo: newOrderInfoPayment,
      };
    case 'RESERVED_PRODUCTS':
      localStorage.setItem(
        'reservedProducts',
        JSON.stringify(action.payload.IDs)
      );
      localStorage.setItem(
        'reservedUntil',
        JSON.stringify(action.payload.reservedUntil)
      );
      return {
        ...state,
        reserved: {
          IDs: action.payload.IDs,
          until: action.payload.reservedUntil,
        },
      };
    case 'CANCEL_RESERVED_PRODUCTS':
      localStorage.removeItem('reservedProducts');
      localStorage.removeItem('reservedUntil');
      return {
        ...state,
        reserved: {
          IDs: [],
          until: undefined,
        },
      };
    case 'ADD_COUPON':
      const orderInfoAddCoupon = {
        ...state.orderInfo,
        ...action.payload,
      };
      localStorage.setItem('orderInfo', JSON.stringify(orderInfoAddCoupon));
      return {
        ...state,
        orderInfo: orderInfoAddCoupon,
      };
    case 'REMOVE_COUPON':
      const orderInfoRemovedCoupon = {
        ...state.orderInfo,
        coupon: {},
      };
      localStorage.setItem('orderInfo', JSON.stringify(orderInfoRemovedCoupon));
      return {
        ...state,
        orderInfo: orderInfoRemovedCoupon,
      };
    case 'ADD_VOUCHER':
      const orderInfoAddVoucher = {
        ...state.orderInfo,
        vouchers: [...(state.orderInfo?.vouchers ?? []), action.payload],
      };
      localStorage.setItem('orderInfo', JSON.stringify(orderInfoAddVoucher));
      return {
        ...state,
        orderInfo: orderInfoAddVoucher,
      };
    case 'REMOVE_VOUCHER':
      const orderInfoRemovedVoucher = {
        ...state.orderInfo,
        vouchers: [
          ...state.orderInfo?.vouchers?.filter(
            (v) => v.code !== action.payload.code
          ),
        ],
      };
      localStorage.setItem(
        'orderInfo',
        JSON.stringify(orderInfoRemovedVoucher)
      );
      return {
        ...state,
        orderInfo: orderInfoRemovedVoucher,
      };
    case 'UPDATE_VOUCHER':
      const updateVoucher = {
        ...state.orderInfo,
        vouchers: [
          ...state.orderInfo?.vouchers?.filter(
            (v) => v.code !== action.payload.code
          ),
          action.payload,
        ],
      };
      localStorage.setItem('orderInfo', JSON.stringify(updateVoucher));
      return {
        ...state,
        orderInfo: updateVoucher,
      };
    case 'CONTAINER_HEIGHT':
      return {
        ...state,
        lastHeight: action.payload,
      };
    case 'UPDATE_GOOGLE_MAPS':
      localStorage.setItem('braunbaerchen.consent-google-maps', action.payload);
      return {
        ...state,
        googleMaps: action.payload,
      };
    default:
      return state;
  }
}

export function StoreProvide(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
