import { Button, Col, Collapse, InputGroup, Row, Form } from 'react-bootstrap';
import LoadingBox from '../LoadingBox';
import { useContext, useEffect, useReducer, useState } from 'react';
import { Store } from 'Store';
import { getTotalOrderPrice, timeout } from 'utils/utils';
import { deliveryCosts } from 'utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_COUPON':
      return { ...state, couponLoading: true };
    case 'SUCCESS_COUPON':
      return { ...state, couponLoading: false };
    default:
      return state;
  }
};

export const removeCouponHandler = ({ ctxDispatch }) => {
  ctxDispatch({
    type: 'REMOVE_COUPON',
  });
};

export const removeVoucherHandler = ({ ctxDispatch, code }) => {
  ctxDispatch({
    type: 'REMOVE_VOUCHER',
    payload: {
      code,
    },
  });
};

export default function CodeInsert({ withEmail, onLoadingChange }) {
  const [{ couponLoading }, dispatch] = useReducer(reducer, {
    loading: false,
    couponLoading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { orderInfo, cart } = state;

  const { pathname } = useLocation();

  const [validated, setValidated] = useState(false);
  const [invalidDiscountCode, setInvalidDiscountCode] = useState(false);
  const [invalidDiscountCodeMessage, setInvalidDiscountCodeMessage] =
    useState('');

  const [codeOpen, setCodeOpen] = useState(false);

  const [orderPrices, setOrderPrices] = useState(
    getTotalOrderPrice(
      cart.cartItems,
      deliveryCosts[orderInfo?.deliveryMethod ?? ''],
      orderInfo?.coupon,
      orderInfo?.vouchers
    )
  );

  useEffect(() => {
    setOrderPrices(
      getTotalOrderPrice(
        cart.cartItems,
        deliveryCosts[orderInfo?.deliveryMethod ?? ''],
        orderInfo?.coupon,
        orderInfo?.vouchers
      )
    );
  }, [cart.cartItems, orderInfo]);

  useEffect(() => {
    if (orderInfo?.coupon?.code) validateCoupon(orderInfo.coupon.code);
    if (orderInfo?.vouchers?.length > 0) {
      for (const voucher of orderInfo.vouchers) {
        updateVouchers(voucher.code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const updateVouchers = async (code) => {
    const validate = await axios.post('/api/voucher/validate', {
      code,
    });
    ctxDispatch({
      type: 'UPDATE_VOUCHER',
      payload: {
        ...validate.data,
        code,
      },
    });
  };

  const validateVoucher = async (code) => {
    try {
      if (orderInfo?.vouchers?.some((v) => v.code === code))
        throw new Error('Wertgutschein bereits eingelöst');
      const validate = await axios.post('/api/voucher/validate', {
        code,
      });
      setInvalidDiscountCode(false);

      await timeout(1500);

      ctxDispatch({
        type: 'ADD_VOUCHER',
        payload: {
          ...validate.data,
          code,
        },
      });
      setValidated(false);
    } catch (error) {
      setInvalidDiscountCode(true);
      setInvalidDiscountCodeMessage(
        error.response?.data?.message || error.message
      );
    }
  };

  const validateCoupon = async (code) => {
    try {
      const validate = await axios.post('/api/coupons/validate', {
        email: withEmail ? orderInfo?.userData?.emailAddress : '',
        code,
      });
      setInvalidDiscountCode(false);

      await timeout(1500);

      ctxDispatch({
        type: 'ADD_COUPON',
        payload: {
          coupon: { ...validate.data, code },
        },
      });
      setValidated(false);
    } catch (error) {
      setInvalidDiscountCode(true);
      setInvalidDiscountCodeMessage(error.response.data.message);
      removeCouponHandler({ ctxDispatch });
    }
  };

  const isVoucherFormat = (code) => {
    const regex = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
    return regex.test(code.toUpperCase());
  };

  const discountSubmitHandler = async (event) => {
    dispatch({ type: 'REQUEST_COUPON' });
    onLoadingChange && onLoadingChange(true);

    //const form = event.currentTarget;
    const code = event.target[0].value;
    //form.checkValidity();
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();

    if (isVoucherFormat(code)) {
      await validateVoucher(code);
    } else {
      await validateCoupon(code);
    }
    onLoadingChange && onLoadingChange(false);
    dispatch({ type: 'SUCCESS_COUPON' });
  };

  return (
    <Row className="text-end">
      {couponLoading ? (
        <Col>
          <LoadingBox margin="4" />
        </Col>
      ) : (
        <>
          <Col
            xs={10}
            md={9}
            className="p-0 toggle-collapse-btn align-items-center cursor-pointer"
            onClick={() => setCodeOpen(!codeOpen)}
            aria-controls="discount-voucher-collapse"
            aria-expanded={codeOpen}
            role="button"
          >
            {orderInfo?.coupon && orderPrices?.couponDiscount
              ? ``
              : orderPrices?.valueToPay > 0
              ? 'Rabattcode oder '
              : 'Rabattcode?'}
            {orderInfo?.vouchers?.length > 0
              ? orderPrices?.valueToPay > 0
                ? orderInfo?.coupon && orderPrices?.couponDiscount
                  ? 'Weiterer Wertgutschein?'
                  : 'weiterer Wertgutschein?'
                : ''
              : 'Wertgutschein?'}
          </Col>
          <Col
            xs={2}
            md={3}
            onClick={() => setCodeOpen(!codeOpen)}
            aria-controls="discount-voucher-collapse"
            aria-expanded={codeOpen}
            role="button"
          >
            <FontAwesomeIcon
              icon={`${codeOpen ? 'angle-up' : 'angle-down'}`}
              size="lg"
            />
          </Col>
          <Collapse in={codeOpen}>
            <div id="discount-voucher-collapse">
              <div className="w-100 d-flex justify-content-end">
                <Form
                  validated={validated}
                  onSubmit={discountSubmitHandler}
                  className="w-100 d-flex justify-content-end"
                >
                  <InputGroup
                    className="my-3 d-flex justify-content-end"
                    hasValidation
                    style={{ maxWidth: '500px' }}
                  >
                    <Form.Control
                      placeholder="Code eingeben"
                      aria-label="Rabattcode"
                      aria-describedby="insert-discount-code"
                      className="discount-input"
                      isInvalid={invalidDiscountCode}
                      required
                    />
                    <Button
                      variant="discount"
                      id="insert-discount-code"
                      type="submit"
                    >
                      Einlösen
                    </Button>
                    <Form.Control.Feedback
                      type="invalid"
                      className="text-start"
                    >
                      {invalidDiscountCodeMessage}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form>
              </div>
            </div>
          </Collapse>
        </>
      )}
    </Row>
  );
}
