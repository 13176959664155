import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const SeoUpdater = (props) => {
  const {
    headerTags: {
      title,
      keywords,
      description,
      image = 'https://braunbaerchen.de/logo.png',
      url,
    },
  } = props;
  const [allTags, setAllTags] = useState([]);

  const titleTemplate = '%s | BraunBärchen';

  const fullTitle = titleTemplate.replace('%s', title);
  const fullUrl = `https://www.braunbaerchen.de${url}`;

  useEffect(() => {
    const titles = getTitles();
    const metaTags = getMetaTags();
    const openGraphTags = getOpenGraphTags();
    const twitterTags = getTwitterTags();

    const tags = [...titles, ...metaTags, ...openGraphTags, ...twitterTags];
    setAllTags(tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, keywords, description, image, url]);

  // Title tags
  const getTitles = () => {
    let titles = [];
    if (title) {
      titles.push(<title key={'head_title'}>{fullTitle}</title>);
      titles.push(
        <meta key={'og_title'} property="og:title" content={fullTitle} />
      );
      titles.push(
        <meta
          key={'twitter_title'}
          property="twitter:title"
          content={fullTitle}
        />
      );
    }
    return titles;
  };

  // Keywords and description tags
  const getMetaTags = () => {
    let metaTags = [];
    if (keywords) {
      metaTags.push(
        <meta key={'head_keywords'} name="keywords" content={keywords} />
      );
    }
    if (description) {
      metaTags.push(
        <meta
          key={'head_description'}
          name="description"
          content={description}
        />
      );
      metaTags.push(
        <meta
          key={'og_description'}
          property="og:description"
          content={description}
        />
      );
      metaTags.push(
        <meta
          key={'twitter_description'}
          name="twitter:description"
          content={description}
        />
      );
    }
    return metaTags;
  };

  // Open Graph tags
  const getOpenGraphTags = () => {
    let ogTags = [];
    if (url) {
      ogTags.push(<meta key={'og_url'} property="og:url" content={fullUrl} />);
      ogTags.push(
        <meta key={'twitter_url'} property="twitter:url" content={fullUrl} />
      );
    }
    if (image) {
      ogTags.push(
        <meta key={'og_image'} property="og:image" content={image} />
      );
    }
    ogTags.push(<meta key={'og_type'} property="og:type" content="website" />);
    return ogTags;
  };

  // Twitter tags
  const getTwitterTags = () => {
    let twitterTags = [];
    twitterTags.push(
      <meta
        key={'twitter_card'}
        name="twitter:card"
        content="summary_large_image"
      />
    );
    if (image) {
      twitterTags.push(
        <meta key={'twitter_image'} property="twitter:image" content={image} />
      );
    }
    return twitterTags;
  };

  return <>{allTags.length > 0 && <Helmet>{allTags}</Helmet>}</>;
};

export default SeoUpdater;
