export const deliveryEnum = {
  Abholen: 'abholen',
  LiefernInnerhalb: 'liefernInnerhalb',
  LiefernDHL: 'liefernDHL',
};

export const paymentMethodEnum = {
  Barzahlung: {
    key: 'Barzahlung',
    value: 'Barzahlung',
  },
  PayPal: {
    key: 'PayPal',
    value: 'PayPal',
    button: 'paypal',
  },
  Klarna: {
    key: 'Klarna',
    value: 'Klarna',
    button: 'pay_now',
  },
  RechnungMitKlarna: {
    key: 'Klarna',
    value: 'Auf Rechnung mit Klarna',
    button: 'pay_later',
  },
  Lastschrift: {
    key: 'PayPal',
    value: 'Lastschrift',
    button: 'sepa',
  },
  Giropay: {
    key: 'PayPal',
    value: 'Giropay',
    button: 'giropay',
  },
  DebitKreditcart: {
    key: 'PayPal',
    value: 'Debit- & Kreditkarte',
    button: 'card',
  },
};

const today = new Date();
const isFreeShippingDay =
  (today.getDate() === 31 && today.getMonth() === 9) || // 31.10.
  (today.getDate() === 1 && today.getMonth() === 10); // 1.11.

export const isGreenWeek =
  today.getMonth() === 10 && today.getDate() >= 23 && today.getDate() <= 29;

export const deliveryCosts = {
  [deliveryEnum.Abholen]: 0.0,
  [deliveryEnum.LiefernInnerhalb]: isFreeShippingDay ? 0 : 2.5,
  [deliveryEnum.LiefernDHL]: isFreeShippingDay ? 0 : 4.99,
  '': 0,
  undefined: 0,
};

export const shopType = {
  Kleidung: 'Kleidung',
  Schlafsack: 'Schlafsack',
  Tonies: 'Tonies',
  Accessoires: 'Accessoires',
};

export const conditionType = {
  SehrGut: 'Sehr gut',
  Gut: 'Gut',
  Geliebt: 'Geliebt',
};

export const couponType = {
  Percentage: 'percentage',
  Fixed: 'fixed',
  FreeDelivery: 'freedelivery',
};
